import React, { createContext, useState, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import useEndpoints from '../api/apiConfig';
import { fetchGet } from '../api/fetch';

export const CityContext = createContext(null);

export const CityProvider = ({ children }) => {
    const [city, setCity] = useLocalStorage('city', 1);
    const [cities, setCities] = useLocalStorage('cities', []);
    
    // Use optional chaining to safely access name
    let selectedCity = cities.find(item => item.id === city);
    let cityName = selectedCity ? selectedCity.name : ''; // Provide a fallback

    function updateCity(city) {
        setCity(city);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchGet(`https://elated-turing.178-124-131-24.plesk.page/city/all`);
                if (result) {
                    setCities(result);
                }
            } catch (error) {
                console.error("Error fetching cities:", error);
            }
        };
        fetchData();
    }, []);

    return (
        <CityContext.Provider value={{ city, updateCity, cities, cityName }}>
            {children}
        </CityContext.Provider>
    );
}